<template>
  <div>
    <div class="search">
      <el-image :src="searchImg" style="width: 46px; height: 46px;" @click="toSearchPage">
      </el-image>
    </div>
    <div class="mylt">
      <el-image :src="myltImg" style="width: 46px; height: 46px;" @click="toCenterPosition">
      </el-image>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        searchImg: require('@/assets/search.png'),
        myltImg: require('@/assets/mylt.png'),
      }
    },
    methods: {
      toSearchPage() {
        // 点击跳转小程序页面
        wx.miniProgram.navigateTo({url: '/etcPackages/pages/PageSearch'});
      },
      // 触发定位
      toCenterPosition() {
        this.$emit('toCenterPosition');
      }
    }
  }
</script>
<style lang="scss" scoped>
  .search {
    position: fixed;
    top: 68px;
    right: 3px;
    cursor: pointer;
  }

  .mylt {
    position: fixed;
    top: 124px;
    right: 3px;
    cursor: pointer;
  }
</style>