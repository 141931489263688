<template>
  <div class="contaner">
    <div class="nav">
      <div class="tabbar fix-nav" id="navInner">
        <div class="title" v-for="(item, index) in data" :key="index">
          <div ref="tabItem" @click="tabItemClick(item, index)">
            <p :class="{ text: typeId === item.serviceTypeConfigId}">{{item.name}}</p>
            <p :class="{ line: typeId === item.serviceTypeConfigId}" style="display: block;margin: auto"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      data: {
        type: Array,
        default: []
      },
      typeId: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        navBarFixed: false,
      }
    },
    mounted() {
      // 事件监听滚动条
      window.addEventListener("scroll", this.watchScroll);
    },
    destroyed() {
      // 移除事件监听
      window.removeEventListener("scroll", this.watchScroll);
    },
    //监听导航滚动宽度触发滑动浏览
    watch: {
      typeId: {
        handler(val) {
          const navInnerElement = document.getElementById('navInner');
          this.data.map((item, index) => {
            if (val === item.serviceTypeConfigId) {
              // 屏幕宽度
              let offWidth = navInnerElement.offsetWidth; 
              let tabItem = this.$refs.tabItem && this.$refs.tabItem[index];
              let list = tabItem && tabItem.offsetWidth;
              //单个菜单宽度
              let offLeft = tabItem && tabItem.offsetLeft;
              //中间值 =( 屏幕宽度 - 导航菜单每一项的宽度 ) / 2;
              let diffWidth = offLeft + (list / 2) - (offWidth / 2);
              navInnerElement.scrollLeft = diffWidth;
            }
          })
        }
      }
    },
    methods: {
      // 导航栏事件
      tabItemClick(item) {
        this.$emit('change', item)
      },
      watchScroll() {
        // 滚动的距离
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        let offsetTop = document.getElementById('navInner').offsetHeight; //高度

        //获取计算滚动距离
        this.data.map((item, index) => {
          if (scrollTop > item.offsetTop - 50 && scrollTop < item.offsetTop + item.offsetHeight) {
            this.$emit('switch', item)
          }
        })
        // 滚动的距离如果大于了元素到顶部的距离时， 实现吸顶效果
        if (scrollTop > offsetTop) {
          this.navBarFixed = true;
        } else {
          this.navBarFixed = false;
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
  .contaner {
    width: 100vw;
    font-family: PingFang SC;
    color: #333333;


    .nav {
      width: 100%;
      height: 100%;

      /* 固定导航 */
      .fix-nav {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
      }

      .text {
        font-family: PingFangSC-Medium, PingFang SC;
        color: #07B562;
      }

      .line {
        width: 28px;
        height: 4px;
        background: #07B562;
        border-radius: 2px;
      }

      .tabbar::-webkit-scrollbar {
        //隐藏滑动条
        display: none;
      }

      .tabbar {
        width: 100%;
        height: 52px;
        line-height: 44px;
        background: #ffffff;
        color: #666666;
        overflow-x: scroll;
        display: flex;
        justify-content: left;
        align-items: center;
        white-space: nowrap;
        font-size: 16px;


        .title {
          width: 100%;
          height: 100%;
          text-align: center;
          padding: 0px 24px;
        }
      }


    }
  }
</style>