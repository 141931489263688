<template>
  <div>
    <!-- 服务点详情 -->
    <div class="info-wrapper">
      <el-popover
        popper-class="popup"
        placement="top"
        v-model="visibleInfo"
        trigger="click"
      >
        <div class="popup-content">
          <span class="close-btn" @click="handleClose">
            <el-image
              :src="clearImg"
              style="width: 20px; height: 20px;display:block;"
            ></el-image>
          </span>
          <div class="serviceinfo">
            <div class="serviceList">
              <el-image
                v-if="serviceItem.guideCover"
                :src="`${$api.fileUrl}/${serviceItem.guideCover || ''}`"
                class="cover"
              >
              </el-image>
              <div class="box">
                <div class="box-content">
                  <div class="title">
                    <span class="name">{{ serviceItem.name }}</span>
                    <div
                      class="location-box"
                      @click.stop="
                        toHere(
                          serviceItem.latitude,
                          serviceItem.longitude,
                          serviceItem.name
                        )
                      "
                    >
                      <el-image
                        class="icon"
                        :src="locationWhiteImg"
                        style="width:12px; height:12px;"
                      ></el-image>
                      <span class="text">导航</span>
                    </div>
                  </div>
                  <Label :labelArr="serviceItem.labelList" />
                </div>
              </div>
            </div>
            <div
              class="audio-box"
              v-if="
                serviceItem.voicePackageConfigRespList &&
                  serviceItem.voicePackageConfigRespList.length
              "
            >
              <AudioPlayer
                :theUrl="audioUrl"
                :theLanguage="selectedLanguage"
                :theControlList="audios.controlList"
                @openDialog="openSelLangDialog"
              />
            </div>
          </div>
        </div>
      </el-popover>
    </div>
    <!-- 路线列表 -->
    <div class="NavRoute" v-if="typeId === '999'">
      <el-popover
        popper-class="popup"
        placement="bottom"
        v-model="visibleList"
        trigger="click"
      >
        <NavRoute :routerLineList="routerLineList" @changeRoute="changeRoute" />
      </el-popover>
    </div>
    <!-- 服务列表 -->
    <div class="list-wrapper" v-else>
      <el-popover
        popper-class="popup"
        placement="bottom"
        v-model="visibleList"
        trigger="click"
      >
        <div class="popup-content" :class="{ open: isExpand }">
          <div>
            <div class="pull-box" @click="handlePull">
              <div class="pull-button"></div>
            </div>
            <div v-for="item in serviceList" :key="item.id" class="serviceList">
              <div style=" width: 84px; height: 84px;">
                <el-image
                  v-if="item.guideCover"
                  :src="`${$api.fileUrl}/${item.guideCover || ''}`"
                  style=" width: 84px; height: 84px;border-rudis:1px"
                >
                </el-image>
              </div>
              <div class="box">
                <div class="box-content">
                  <div class="title">
                    <span class="name">{{ item.name }}</span>
                    <div
                      class="location-box"
                      @click.stop="
                        toHere(item.latitude, item.longitude, item.name)
                      "
                    >
                      <el-image
                        class="icon"
                        :src="locationWhiteImg"
                        style="width:12px; height:12px;"
                      ></el-image>
                      <span class="text">导航</span>
                    </div>
                  </div>
                  <div class="content">
                    <Label :labelArr="item.labelList" />
                    <div
                      @click.stop="toDetail(item)"
                      v-if="item.introduceDetail"
                      class="detail"
                    >
                      查看详情
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-popover>
    </div>
    <div class="NavRoute" v-if="detailVisible">
      <van-popup
        round
        v-model="detailVisible"
        :style="{ padding: '16px' }"
        :close-on-click-overlay="false"
      >
        <div class="popup-detail">
          <div>{{ detail.name }}</div>
          <div class="content" v-html="detail.introduceDetail"></div>
          <div class="btn" @click="detailVisible = false">关闭</div>
        </div></van-popup
      >
    </div>
    <van-action-sheet
      v-model="showActionSheet"
      :actions="languageList"
      cancel-text="取消"
      close-on-click-action
      @select="onSelect"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import Label from "../components/Label.vue";
import NavRoute from "../components/NavRoute.vue";
import AudioPlayer from "@/components/AudioPlayer.vue";
import bus from "@/utils/bus";
const LANGUAGE = {
  "1": "中文",
  "2": "英语",
  "3": "日语",
  "4": "韩语",
};
export default {
  props: {
    showType: {
      type: String,
      default: null,
    },
    serviceList: {
      type: Array,
      default() {
        return [];
      },
    },
    serviceItem: {
      type: Object,
      default() {
        return {};
      },
    },
    typeId: {
      type: String,
      default: null,
    },
    routerLineList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      locationWhiteImg: require("@/assets/location-white.png"),
      clearImg: require("@/assets/clear.png"),
      isExpand: false,
      visibleInfo: false,
      visibleList: false,
      audios: { controlList: "onlyOnePlaying" },
      showActionSheet: false,
      languageList: [],
      selectedLanguage: "1", // 1、中文 2、英语 3、日语 4、韩语,
      audioUrl: "",
      detailVisible: false,
      detail: {},
    };
  },
  components: {
    AudioPlayer,
    Label,
    NavRoute,
  },
  watch: {
    showType(curVal, oldVal) {
      if (curVal !== oldVal) {
        if (curVal === "info") {
          this.visibleInfo = true;
          this.visibleList = false;
        } else {
          this.visibleInfo = false;
          this.visibleList = true;
        }
        bus.$emit("closeAudioPlayer");
      }
    },
    serviceItem(curVal, oldVal) {
      if (curVal !== oldVal && curVal.voicePackageConfigRespList.length) {
        this.languageList = curVal.voicePackageConfigRespList.map((item) => {
          return {
            id: item["id"],
            style: item["style"],
            name: LANGUAGE[item["style"]],
            addr: item["addr"],
          };
        });
        this.selectedLanguage = this.languageList[0]["style"];
        this.audioUrl = `${this.$api.fileUrl}/${this.languageList[0]["addr"]}`;
      }
    },
  },
  methods: {
    toDetail(item) {
      this.detailVisible = true;
      this.detail = item;
    },
    handlePull() {
      this.isExpand = !this.isExpand;
    },
    handleClose() {
      this.$emit("closePopup");
    },
    // 切换路线导航
    changeRoute(item) {
      this.$emit("changeRoute", item);
    },
    // 导航
    toHere(latitude, longitude, name) {
      if (!latitude || !longitude) {
        return;
      }
      wx.miniProgram.navigateTo({
        url: `/subPackages/pages/PageOpenLocation?latitude=${latitude}&longitude=${longitude}&name=${name}`,
      });
    },
    openSelLangDialog() {
      this.showActionSheet = true;
    },
    onSelect(item) {
      bus.$emit("closeAudioPlayer");
      this.selectedLanguage = item.style;
      this.audioUrl = `${this.$api.fileUrl}/${item.addr}`;
    },
    onCancel() {
      this.showActionSheet = false;
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  font: 12px/16px Verdana, Helvetica, Arial, sans-serif;
}

.info-wrapper {
  .popup {
    .popup-content {
      position: relative;
      .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #f5f5f5;
        color: #ccc;
        text-align: center;
      }

      box-sizing: border-box;
    }
  }

  .serviceinfo {
    background: #ffffff;
    border-radius: 4px;

    .serviceList {
      display: flex;
      flex: 1;
      .cover {
        flex: 0 0 84px;
        width: 84px;
        height: 84px;
        border-radius: 1px;
      }
      .box {
        flex: 1;
        .box-content {
          margin-left: 16px;
          .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 13px;
            margin-bottom: 13px;
            .name {
              display: inline-block;
              width: 122px;
              font-size: 18px;
              color: #313131;
              font-weight: 500;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .location-box {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 36px;
              width: 57px;
              height: 24px;
              background: #24c27d;
              border-radius: 14px;
              font-family: PingFang SC;
              font-weight: 300;
              font-size: 12px;
              line-height: 24px;
              text-align: center;
              color: #ffffff;
              .text {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
    .audio-box {
      border-top: 1px solid #ebedf0;
    }
  }
}

.list-wrapper {
  .popup {
    .popup-content {
      height: 135px;
      overflow-y: auto;
      padding: 0 8px;

      &.open {
        height: 375px;
      }

      .pull-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        line-height: 20px;
        margin-bottom: 12px;

        .pull-button {
          display: block;
          width: 32px;
          height: 4px;
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .serviceList {
    display: flex;
    flex: 1;
    height: auto;
    background: #ffffff;
    margin-bottom: 20px;

    .box {
      flex: 1;
      .box-content {
        margin-left: 16px;
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 13px;
          margin-bottom: 13px;
          .name {
            display: inline-block;
            width: 168px;
            font-size: 18px;
            color: #313131;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .location-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 57px;
            height: 24px;
            background: #24c27d;
            border-radius: 14px;
            font-family: PingFang SC;
            font-weight: 300;
            font-size: 12px;
            line-height: 24px;
            color: #ffffff;
            .text {
              margin-left: 5px;
            }
          }
        }
        .content {
          display: flex;
          justify-content: space-between;
          .detail {
            color: #07b562;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.NavRoute {
  .popup {
    .popup-content {
      overflow-y: auto;
    }
  }
}
</style>
<style lang="scss">
.info-wrapper {
  .popup {
    bottom: 53px;
    left: 50%;
    width: 90%;
    box-sizing: border-box;
    transform: translateX(-50%);
    padding: 0px;
  }

  .serviceList {
    margin-bottom: 0px;
    padding: 8px;
  }
}

.list-wrapper {
  .el-image__inner {
    border-radius: 4px;
  }

  .el-popover {
    border: none;
    border-radius: 24px 24px 0px 0px;
  }

  .popup {
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
  }
}

.NavRoute {
  .popup {
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .el-popover {
    height: 162px;
    border-radius: 0px 0px 0px 0px;
  }
}
.popup-detail {
  text-align: left;
  width: 300px;
  position: relative;
  .content {
    color: #666666;
    font-size: 12px;
    margin-top: 10px;
    max-height: 400px;
    overflow-y: scroll;
    img {
      width: 100%;
      margin: 10px auto;
    }
  }
  .btn {
    width: 100%;
    text-align: center;
    color: #07b562;
    // position: absolute;
    bottom: 0;
    z-index: 6;
    background: #fff;
    padding-top: 10px;
  }
}
</style>
