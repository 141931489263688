<template>
  <div>
    <!-- 导航栏 -->
    <NavBar :data='navList' :typeId='serviceTypeId' @change='changeTab' />

    <!-- 地图 -->
    <Map ref="mapRef" :serviceList='serviceList' :typeId='serviceTypeId' :position="position" :routerLineItem='routerLineItem' :drawingMapObj='drawingMapObj' @getServiceDetail='getServiceDetail' :zoom='zoom' />

    <!-- 弹框 -->
    <Popover :showType="showType" :serviceList='serviceList' :serviceItem='serviceItem' :typeId='serviceTypeId' :routerLineList='routerLineList' @closePopup='closePopup' @changeRoute='changeRoute' />

    <!-- 搜索 -->
    <Search @toCenterPosition='toCenterPosition' />
  </div>
</template>
<script>
import NavBar from './components/NavBar'
import Map from './components/Map'
import Popover from './components/Popover'
import Search from './components/Search'
const ERR_OK = 0;
export default {
  data() {
    return {
      //导航栏
      navList: [],
      // 服务点列表
      serviceList: [],
      // 弹窗显示类型info, list, router
      showType: '',
      //服务点详情
      serviceItem: {},
      // 服务类型id
      serviceTypeId: '',
      position: { longitude: '', latitude: '', selfLng: '', selfLat: '' },
      // 是否展示详情弹框
      showPopup: false,
      // 路线列表(路线1，路线2等)
      routerLineList: [],
      // 路线项
      routerLineItem: {},
      // 手绘地图数据
      drawingMapObj: {},
      //地图层级
      zoom: 14,
    }
  },
  components: {
    NavBar,
    Map,
    Popover,
    Search
  },
  created() {
    document.title = '智慧导览';
  },
  mounted() {
    this.urlParms = this.$utils.getPathParam(window.location.href);
    if (this.urlParms) {
      this.initServiceId = this.urlParms.id;
      this.position.longitude = this.position.selfLng = this.urlParms.longitude;
      this.position.latitude = this.position.selfLat = this.urlParms.latitude;
    }
    this.init();
  },
  methods: {
    async init() {
      await this.getNavList()
      // 根据ID查询服务点详情。
      if (this.initServiceId) {
        this.zoom = 18
        await this.getServiceDetail(this.initServiceId);
      }
      // 查询服务点列表
      await this.getServiceList();
      this.getHandDrawMap();
      this.initServiceId = '';
    },
    // 获取导航列表
    async getNavList() {
      const ret = await this.$get(`${this.$api.navListApi}`);
      if (ret.code === ERR_OK) {
        this.navList = ret.data
      }
      // 如果没有初始化服务ID，默认使用第1个导航菜单的服务ID。
      if (!this.initServiceId) {
        this.serviceTypeId = this.navList[0].serviceTypeConfigId;
      }
    },
    // 获取服务列表
    async getServiceList() {
      // 如果服务类型为路线，查询路线
      if (this.serviceTypeId === '999') {
        await this.getRouteList();
      } else {
        // 如果有服务类型，根据服务类型查询服务列表
        await this.getServiceListByTypeId(this.serviceTypeId);
      }
    },
    //切换导览导航
    changeTab(item) {
      this.$refs.mapRef.clearMap();

      this.serviceTypeId = item.serviceTypeConfigId
      this.position.longitude = this.position.selfLng
      this.position.latitude = this.position.selfLat
      if (item.serviceTypeConfigId === '999') {
        this.getRouteList();
      } else {
        this.getServiceListByTypeId(item.serviceTypeConfigId);
      }
    },
    closePopup() {
      if (this.serviceTypeId === '999') {
        this.showType = 'router';
      } else {
        this.showType = 'list';
      }
    },
    // 根据服务类型获取服务列表
    async getServiceListByTypeId(typeId) {
      if (!typeId) {
        return;
      }
      if (!this.initServiceId) {
        this.showType = 'list';
      }
      const ret = await this.$get(`${this.$api.getServiceListByTypeIdApi}/${typeId}`);
      if (ret.code === ERR_OK) {
        this.serviceList = ret.data
      }
    },
    setServiceTypeId(item) {
      this.serviceTypeId = item.serviceTypeConfigId;
    },
    //获取服务点详情
    async getServiceDetail(serviceId) {
      if (!serviceId) {
        return;
      }
      this.showType = 'info';
      const ret = await this.$get(`${this.$api.getServiceDetailApi}/${serviceId}`);
      if (ret.code === ERR_OK) {
        this.serviceItem = ret.data;
      }
      // 如果有初始化服务ID，使用服务点详情的服务类型ID字段做为初始服务类型。
      if (this.initServiceId) {
        const { serviceTypeConfigId, longitude, latitude } = this.serviceItem
        this.serviceTypeId = serviceTypeConfigId;
        this.position.longitude = this.position.selfLng = longitude;
        this.position.latitude = this.position.selfLat = latitude;
      }
    },
    // 获取线路
    async getRouteList() {
      this.showType = 'router';
      const ret = await this.$get(this.$api.getPathApi);
      if (ret.code === ERR_OK) {
        this.routerLineList = ret.data
        this.routerLineItem = ret.data[0]
      }
    },
    // 切换路线
    changeRoute(item) {
      if (item === this.routerLineItem) {
        return
      }

      this.routerLineItem = item;
      this.$refs.mapRef.clearMap();
    },
    // 到地图中心位置
    toCenterPosition() {
      const { longitude, latitude } = this.position
      if (!longitude || !latitude) {
        return
      }
      this.$refs.mapRef.toLocate(longitude, latitude)
    },
    // 获取手绘地图信息
    async getHandDrawMap() {
      const ret = await this.$get(`${this.$api.getDrawingMapApi}`);
      if (ret.code === ERR_OK) {
        this.drawingMapObj = ret.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>