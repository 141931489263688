<template>
  <div class="map-wrapper">
    <div class="map" ref="mapRef"></div>
  </div>
</template>

<script>
let Map = window.qq.maps.Map
let Point = window.qq.maps.Point
let Size = window.qq.maps.Size
let LatLng = window.qq.maps.LatLng
let Marker = window.qq.maps.Marker
let Polyline = window.qq.maps.Polyline
let MarkerImage = window.qq.maps.MarkerImage
let ImageMapType = window.qq.maps.ImageMapType
let MVCArray = window.qq.maps.MVCArray
let MarkerCluster = window.qq.maps.MarkerCluster
let Event = window.qq.maps.event
export default {
  props: {
    zoom: {
      type: Number,
      default: 14
    },
    serviceList: {
      type: Array,
      default() {
        return []
      }
    },
    typeId: {
      type: String,
      default: null
    },
    routerLineItem: {
      type: Object,
      default() {
        return {}
      }
    },
    drawingMapObj: {
      type: Object,
      default() {
        return {}
      }
    },
    position: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      //景区经纬度
      scenicLatitude: 28.127962,
      scenicLongitude: 112.926837
    }
  },
  watch: {
    zoom(value) {
      this.map.setZoom(value);
    },
    // 监听服务列表
    serviceList(curVal, oldVal) {
      if (curVal !== oldVal) {
        this.addMarker2Map(curVal)
      }
    },
    // 监听路线项
    routerLineItem(curVal, oldVal) {
      if (this.typeId === '999' && curVal !== oldVal) {
        this.addRouteLine(curVal)
      }
    },
    //监听手绘地图数据
    drawingMapObj(curVal, oldVal) {
      if (curVal !== oldVal) {
        this.setDrawingMap(curVal);
      }
    },
    position: {
      handler: function (curPosition) {
        const { longitude, latitude, selfLng, selfLat } = curPosition
        if (selfLng && selfLat) {
          this.setCenterPosition(selfLng, selfLat)
        }
        if (longitude && latitude) {
          this.toLocate(longitude, latitude)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.initMap()
    },
    // 用户打点
    setCenterPosition(longitude, latitude) {
      if (this.userMaker) {
        this.userMaker.setMap(null);
      }
      const anchor = new Point(9, 0),
        size = new Size(36, 36),
        scaleSize = new Size(36, 36),
        origin = new Point(0, 0)
      this.userMaker = new Marker({
        position: new LatLng(latitude, longitude),
        map: this.map,
        icon: new MarkerImage(require(`@/assets/position.png`), size, origin, anchor, scaleSize)

      });

      this.map.setCenter(new LatLng(this.scenicLatitude, this.scenicLongitude));
    },
    toLocate(longitude, latitude) {
      if (!longitude || !latitude) {
        return
      }
      this.map.panTo(new LatLng(latitude, longitude))

    },
    initMap() {
      this.map = new Map(this.$refs.mapRef, {
        center: new LatLng(this.scenicLatitude, this.scenicLongitude),
        zoom: this.zoom
      })
    },
    //手绘地图
    setDrawingMap(drawingMapObj) {
      if (!drawingMapObj || !drawingMapObj.addr) {
        return;
      }

      let earthLayer = new ImageMapType({
        name: '洋湖湿地',
        alt: '',
        tileSize: new Size(256, 256),
        minZoom: 1,
        maxZoom: 5,
        getTileUrl: (tile, zoom) => {
          const z = zoom, x = tile.x, y = tile.y;
          return `${drawingMapObj.addr}/${z}/${x}_${y}.png`
        }
      })

      this.map.overlayMapTypes.push(earthLayer)
    },
    getIcon(poi, size, origin, anchor, scaleSize) {
      return new MarkerImage(poi.poiIconUrl, size, origin, anchor, scaleSize)
    },
    addMarker(serviceList) {
      let anchor = new Point(9, 0)
        , size = new Size(22, 22)
        , scaleSize = new Size(22, 22)
        , selectedSize = new Size(36, 36)
        , selectedScaleSize = new Size(36, 36)
        , origin = new Point(0, 0)
        , defaultIcon = ''
        , selectedIcon = '';
      // 遍历服务列表进行打点
      const markers = new MVCArray();
      serviceList.forEach(poi => {
        poi.poiIconUrl = this.$api.fileUrl + '/' + poi.icon;
        defaultIcon = this.getIcon(poi, size, origin, anchor, scaleSize);
        selectedIcon = this.getIcon(poi, selectedSize, origin, anchor, selectedScaleSize);
        // 1.打点
        let marker = new Marker({
          position: new LatLng(poi.latitude, poi.longitude),
          icon: defaultIcon,
          map: this.map,
          id: poi.id
        })
        // 2.打点绑定事件
        Event.addListener(marker, 'click', res => {
          // 2.1.点击打点当前放大,其它缩小
          markers.forEach(m => {
            if (m.id === res.target.id) {
              m.setIcon(selectedIcon)
            } else {
              m.setIcon(defaultIcon)
            }
          })
          // 2.2.将中心位置移到打点
          this.map.panTo(new LatLng(res.latLng.lat, res.latLng.lng))
          // 2.3.显示打点详情
          this.$emit('getServiceDetail', res.target.id);
        });
        markers.push(marker);
      });
      return markers;
    },
    addMarkerClusterer(markers) {
      // 点聚合
      this.markerClusterer = new MarkerCluster({
        map: this.map,
        minimumClusterSize:2,
        markers:markers,
        zoomOnClick:true,
        gridSize:30,
        averageCenter:true,
        maxZoom:18
      });
    },
    // 给地图增加打点和点聚合
    addMarker2Map(serviceList) {
      // 1.增加打点
      let markers = this.addMarker(serviceList);
      // 2.增加点聚合
      this.addMarkerClusterer(markers);
    },
    // 路线
    addRouteLine(routerLineItem) {
      let anchor = new Point(9, 0)
        , size = new Size(24, 30)
        , scaleSize = new Size(24, 30)
        , selectedSize = new Size(34, 40)
        , selectedScaleSize = new Size(34, 40)
        , origin = new Point(0, 0)
        , defaultIcon = ''
        , selectedIcon = '';

      // 1.绘制路线
      this.Polyline = new Polyline({
        map: this.map,
        path: routerLineItem.list.map(router => new LatLng(router.y, router.x)),
        strokeColor: '#FFA50E',
        strokeWeight: 6
      })
      // 2.在路线节点打点
      this.markersline = []
      routerLineItem.data.forEach(poi => {
        poi.poiIconUrl = require(`@/static/images/guide/route-${poi.order + 1}.png`);
        defaultIcon = this.getIcon(poi, size, origin, anchor, scaleSize);
        selectedIcon = this.getIcon(poi, selectedSize, origin, anchor, selectedScaleSize);
        // 2.1.打点
        let marker = new Marker({
          position: new LatLng(poi.latitude, poi.longitude),
          icon: defaultIcon,
          map: this.map,
          id: poi.id
        })
        Event.addListener(marker, 'click', res => {
          // 2.2.点击打点当前放大,其它缩小
          this.markersline.forEach(m => {
            if (m.id === res.target.id) {
              m.setIcon(selectedIcon)
            } else {
              m.setIcon(defaultIcon)
            }
          })
          // 2.2.将中心位置移到打点
          this.map.panTo(new LatLng(res.latLng.lat, res.latLng.lng))
          this.$emit('getServiceDetail', res.target.id);
        })
        this.markersline.push(marker)
      })
    },
    // 清除地图标点
    clearMap() {
      if (this.markerClusterer) {
        this.markerClusterer.clearMarkers()
      }
      if (this.markersline) {
        for (let i in this.markersline) {
          this.markersline[i].setMap(null);
        }
      }
      if (this.Polyline) {
        this.Polyline.setVisible(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0px;
  padding: 0px;
}

body {
  font: 12px/16px Verdana, Helvetica, Arial, sans-serif;
}

.map-wrapper {
  .map {
    width: 100vw;
    height: 100vh;
  }
}
</style>