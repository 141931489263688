<template>
  <div class="main-wrap">
    <audio ref="audio" 
      class="none" 
      :src="url" 
      :preload="audio.preload"
      @play="onPlay" 
      @error="onError"
      @pause="onPause" 
      @timeupdate="onTimeupdate" 
      @loadedmetadata="onLoadedmetadata">
    </audio>
    <div class="audio-player">
      <el-image class="play-btn"  @click="startPlayOrPause" :src="require(`@/static/images/guide/${audio.playing ? 'pause' : 'play'}.png`)"></el-image>
      <span class="lang-btn" @click="openSelLangDialog">{{theLanguage | formatLanguage}}</span>
      <el-slider 
        class="slider" 
        v-show="!controlList.noProcess" 
        v-model="sliderTime" 
        :show-tooltip="false"
        @change="changeCurrentTime">
      </el-slider>  
      <span class="progress">{{ audio.currentTime | formatSecond}}/{{ audio.maxTime | formatSecond }}</span>
    </div>
  </div>
</template>

<script>
  import bus from '@/utils/bus'
  const LANGUAGE = {
    "1": "中文", 
    "2": "英语",
    "3": "日语",
    "4": "韩语"
  };

  function realFormatSecond(second) {
    var secondType = typeof second

    if (secondType === 'number' || secondType === 'string') {
      second = parseInt(second)

      var hours = Math.floor(second / 3600)
      second = second - hours * 3600
      var mimute = Math.floor(second / 60)
      second = second - mimute * 60

      return ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2)
    } else {
      return '00:00'
    }
  }

  export default {
    name: 'AudioPlayer',
    props: {
      theUrl: {
        type: String,
        required: true,
      },
      theLanguage: {
        type: String,
        required: true
      },
      theControlList: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        url: this.theUrl,
        audio: {
          currentTime: 0,
          maxTime: 0,
          playing: false,
          preload: 'auto'
        },
        sliderTime: 0,
        controlList: {
          // 不显示进度条
          noProcess: false,
          // 只能播放一个
          onlyOnePlaying: false
        }
      }
    },
    watch: {
      theUrl(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.url = newVal;
          this.reLoad();
        }
      }
    },
    methods: {
      setControlList () {
        let controlList = this.theControlList.split(' ')
        controlList.forEach((item) => {
          if(this.controlList[item] !== undefined){
            this.controlList[item] = true
          }
        })
      },
      // 播放跳转
      changeCurrentTime(index) {
        this.$refs.audio.currentTime = parseInt(index / 100 * this.audio.maxTime)
      },
      startPlayOrPause() {
        return this.audio.playing ? this.pausePlay() : this.startPlay()
      },
      reLoad() {
        this.audio.playing = false;
        this.$refs.audio.load();
      },
      // 开始播放
      startPlay() {
        this.$refs.audio.play()
      },
      // 暂停
      pausePlay() {
        this.$refs.audio.pause()
      },
      // 当音频暂停
      onPause () {
        this.audio.playing = false
      },
      // 当发生错误
      onError () {
      },
      // 当音频开始播放
      onPlay (res) {
        this.audio.playing = true
        if(!this.controlList.onlyOnePlaying){
          return 
        }

        let target = res.target
        let audios = document.getElementsByTagName('audio');

        [...audios].forEach((item) => {
          if(item !== target){
            item.pause()
          }
        })
      },
      // 当timeupdate事件大概每秒一次，用来更新音频流的当前播放时间
      onTimeupdate(res) {
        this.audio.currentTime = res.target.currentTime
        this.sliderTime = parseInt(this.audio.currentTime / this.audio.maxTime * 100)
      },
      // 当加载语音流元数据完成后，会触发该事件的回调函数
      // 语音元数据主要是语音的长度之类的数据
      onLoadedmetadata(res) {
        this.audio.maxTime = parseInt(res.target.duration)
      },
      openSelLangDialog() {
        this.$emit('openDialog')
      },
      bindEvent() {
        bus.$on('closeAudioPlayer', () => {this.reLoad()});
      }
    },
    filters: {
      formatSecond(second = 0) {
        return realFormatSecond(second)
      },
      formatLanguage(type) {
        return LANGUAGE[type] || ''
      }
    },
    created() {
      this.setControlList()
      this.bindEvent()
    }
  }

</script>

<style lang="scss" scoped>
.main-wrap {
  padding: 8px 11px 8px 4px;
  ::v-deep.el-slider__button {
    width: 8px;
    height: 8px;
    background: #24C27D;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  }
  ::v-deep.el-slider__runway {
    height: 4px;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 2px;
  }
  ::v-deep.el-slider__bar {
    background-color:#24C27D;
  }

  .audio-player {
    display: flex;
    align-items: center;
    .play-btn {
      flex: 0 0 40px;
      width: 40px;
      height: 40px;
    }
    .lang-btn {
      flex: 0 0 48px;
      margin-left: 8px;
      width: 48px;
      height: 20px;
      padding: 0 6px;
      background: rgba(36, 194, 125, 0.1);
      border-radius: 2px;
      font-family: PingFang SC;
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #24C27D;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .slider {
      flex: 1;
      position: relative;
      margin-left: 14px;
    }
    .progress {
      margin-left: 6px;
      flex: 0 0 64px;
      font-family: PingFang SC;
      font-weight: 300;
      font-size: 10px;
      line-height: 14px;
      color: #999999;
    }
  }
  .none {
    display: none;
  }
}
</style>
