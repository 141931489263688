<template>
  <div class="contaner">
    <div>
      <ul class="title-wrap">
        <li :class="[activeId==item.name ? 'active': 'title']" v-for="(item, index) in routerLineList" :key="index"
          @click="navClick(item)">{{item.name}}</li>
        <li v-if="!routerLineList.length">暂无路线推荐</li>
      </ul>
      <div class="spot-list">
        <div class="spot" v-for="(item,index) in lineData" :key="index">
          <div class="roll">
            <div class="num">
              <el-image :src="require(`@/static/images/guide/sign-${index+1}.png`)" style=" width: 26px; height: 26px;">
              </el-image>
            </div>
            <div v-if="lineData.length > index+ 1" class="Gradual">
              <el-image :src="require(`@/static/images/guide/spot.png`)" style=" width: 47px; height: 2px;">
              </el-image>
            </div>
          </div>
          <div class="name">{{item.name.length > 6 ? item.name.substring(0,6)+'...':item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      routerLineList: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        activeId: null,
        lineData: []
      };
    },

    methods: {
      navClick(item) {
        this.lineData = item.data
        this.activeId = item.name;
        this.$emit("changeRoute", item);
      },
    },
    watch: {
      routerLineList() {
        this.navClick(this.routerLineList[0]);
      },
    },
  }
</script>
<style lang="scss" scoped>
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none
  }

  .contaner {
    .title-wrap {
      display: flex;
      white-space: nowrap;
      overflow-y: hidden;
      overflow-x: scroll;
      height: 30px;



      li {
        max-width: 200px;
        margin: 0px 20px;
        line-height: 30px;
      }

      .active {
        font-size: 16px;
        color: #08B563;
      }

      .title {
        font-size: 14px;
        color: #666666;
      }
    }

    .spot-list {
      margin-left: 5vw;
      margin-top: 15px;
      height: 70px;
      overflow: hidden;
      white-space: nowrap;
      overflow-y: hidden;
      overflow-x: scroll;

      .roll {
        display: flex;
        justify-content: center;
        position: relative;
      }

      .spot {
        width: 72px;
        display: inline-block;
        margin-right: 48px;
        text-align: center;
      }

      .spot:last-child {
        margin-right: 10px;
      }

      .num {
        width: 26px;
        height: 26px;

        line-height: 17px;
        color: white;

        margin: 0 auto;
      }

      .name {
        min-width: 48px;
        height: 17px;
        font-size: 12px;
        line-height: 12px;
        color: #999999;
        margin-top: 10px;
        /* width: 84px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; */
      }

      .Gradual {
        position: absolute;
        left: 72px;
      }

    }
  }
</style>