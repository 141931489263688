<template>
  <div class="label-box">
    <div class="label" v-for="item in labelArr" :key="item.id">
      <p class="label-content">{{item.labName}}</p>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      labelArr: {
        type: Array,
        default: function () {
          return []
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .label-box {
    display: flex;
    white-space: normal;
    flex-direction: row;
    flex-wrap: wrap;

    .label {
      margin-right: 4px;
      background: rgba(7, 181, 98, 0.04);
      border-radius: 2px;
      padding: 3px 8px;
      margin-bottom: 4px;
      .label-content {
        max-width: 48rpx;
        font-size: 12px;
        font-weight: 400;
        color: #07B562;
        opacity: 1;
        line-height: 17px;
        white-space: nowrap;
      }
    }
  }
</style>